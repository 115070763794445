
import {
  Component,
  Vue
} from 'vue-property-decorator'
import {
  ToastModule
} from '@/store/modules/ToastModule'
import ClientModalService from '../services/client-modal-service'
import Countries from '@/data/countries.json'
// import { ClientModule } from '../store/ClientModule'
import { MasterModule } from '@/views/invoice/store/MasterModule'
import {
  AuthModule
} from '@/store/modules/AuthModule'
import {
  Validations
} from 'vuelidate-property-decorators'
import {
  Vuelidate
} from 'vuelidate'
import {
  email,
  alpha,
  alphaNum,
  numeric,
  required,
  minLength,
  maxLength
} from 'vuelidate/lib/validators'

  @Component({
    name: 'ClientRegister'
  })
export default class ClientRegister extends Vue {
    protected tabIndex = 1
    protected isEditForm = false
    protected addressTypes = ['Secondary', 'Temporary', 'Other']
    protected titles = ['Mr', 'Mrs', 'Miss']
    protected countries = Countries
    protected contactList: any = []
    protected contactMember: any = null
    protected addressType: any = null
    protected client: any = {
      id: null,
      name: null,
      display_name: null,
      code: null,
      email: null,
      vat_no: null,
      registration_no: null,
      mobile: null,
      phone: null,
      other: null,
      fax: null,
      web_site: null
    }

    protected primaryAddress: any = {
      id: null,
      type: null,
      address: null,
      postal_code: null,
      country: null
    }

    protected address: any = {
      id: null,
      type: null,
      address: null,
      postal_code: null,
      country: null
    }

    protected contact: any = {
      id: null,
      title: null,
      first_name: null,
      last_name: null,
      department: null,
      phone: null,
      mobile: null,
      email: null,
      is_default_contact: null
    }

    $v: Vuelidate

    @Validations()
    validations = {
      client: {
        name: {
          required,
          minLength: minLength(5)
        },
        display_name: {
          required,
          minLength: minLength(5)
        },
        email: {
          email
        },
        code: {
          alpha,
          required,
          minLength: minLength(6),
          maxLength: maxLength(8)
        },
        vat_no: {
          numeric
        },
        registration_no: {
          alphaNum
        },
        phone: {
          minLength: minLength(10),
          numeric
        },
        mobile: {
          minLength: minLength(10),
          numeric
        },
        other: {
          minLength: minLength(10),
          numeric
        },
        fax: {
          minLength: minLength(10),
          numeric
        },
        web_site: {
          minLength: minLength(8),
          maxLength: maxLength(100)
        }
      }
    }

    public open () {
      (this.$refs.ClientModal as any).show()
    }

    public close () {
      this.isEditForm = false
      this.cancel();
      (this.$refs.ClientModal as any).hide()
    }

    protected populateClient (id = 0) {
      if (id === 0) return false
      ClientModalService.getClient(id).then((response) => {
        this.isEditForm = true
        this.client = response.data
        if (response.data.primary_address !== null) {
          this.primaryAddress = {
            id: response.data.primary_address.id,
            address: response.data.primary_address.address,
            postal_code: response.data.primary_address.postal_code,
            country: response.data.primary_address.country
          }
        }
        this.setContactList(response.data.contacts)
      })
    }

    protected setContactList (contacts) {
      this.contactList = []
      contacts.forEach((contact) => {
        this.contactList.push({
          value: contact.id,
          text: contact.title + ' ' + contact.first_name + ' ' + contact.last_name
        })
      })
    }

    protected setAddress (type) {
      this.reset(this.address)
      ClientModalService.getAddress(this.client.id, type).then((response) => {
        if (response.data !== 'empty') {
          this.address = response.data
        }
      })
    }

    protected setContact (contactId) {
      if (contactId === null) { this.reset(this.contact) } else {
        this.reset(this.contact)
        ClientModalService.getContactDetails(contactId).then((response) => {
          this.contact = response.data
          this.contact.is_default_contact = response.data.is_default_contact === 1
        })
      }
    }

    protected isNumber ($event: any) {
      const charCode = ($event.keyCode ? $event.keyCode : $event.which)
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        $event.preventDefault()
      } else {
        return true
      }
    }

    protected generateClientCode () {
      this.client.code = this.client.name.substring(0, 8).replace(/\s/g, '').toUpperCase()
      if (this.client.code.length > 6) this.checkClientCode(this.client.code)
    }

    protected checkClientCode (code: any) {
      ClientModalService.checkClient(code).then(response => {
        if (response.data.status) alert('Client Already Exist')
      })
    }

    protected save () {
      this.client.status = 'active'
      this.primaryAddress.type = 'Primary'
      this.address.type = this.addressType
      this.primaryAddress.source_type = this.address.source_type = this.contact.source_type = 'Client'
      this.primaryAddress.source_id = this.address.source_id = this.contact.source_id = this.client.id
      this.client.created_user = this.primaryAddress.created_user = this.address.created_user = this.contact.created_user = AuthModule.user.fullname
      this.client.modified_user = this.primaryAddress.modified_user = this.address.modified_user = this.contact.modified_user = AuthModule.user.fullname

      const client: any = {}
      client.basic = this.client
      client.primary_address = this.primaryAddress.address !== null || this.primaryAddress.postal_code !== null || this.primaryAddress.country !== null ? this.primaryAddress : null
      client.address = this.address.type !== null && (this.address.address !== null || this.address.postal_code !== null || this.address.country !== null) ? this.address : null
      client.contact = this.contact.title !== null || this.contact.first_name !== null || this.contact.last_name !== null ? this.contact : null

      delete client.basic.primary_address
      delete client.basic.contacts

      if (this.isEditForm) this.update(client)
      else this.create(client)
    }

    protected create (client: any) {
      ClientModalService.create(client).then((response) => {
        ToastModule.message(response.data.message)
        MasterModule.setClientChange()
        this.close()
        // (this.$parent as any).populateClients()
      }).catch(error => {
        console.log(error)
      })
    }

    protected update (client: any) {
      ClientModalService.update(client).then((response) => {
        ToastModule.message(response.data.message)
        MasterModule.setClientChange()
        this.close()
        // (this.$parent as any).populateClients()
      }).catch(error => {
        console.log(error)
      })
    }

    protected cancel () {
      this.reset(this.client)
      this.reset(this.primaryAddress)
      this.reset(this.address)
      this.reset(this.contact)
      this.reset(this.contactMember)
      this.contactList = []
      this.contactMember = null
      this.addressType = null
      // MasterModule.resetClientChange()
      this.$nextTick(() => {
        this.$v.client.$reset()
      })
    }

    protected reset (obj: any) {
      for (const key in obj) {
        obj[key] = null
      }
    }
}

