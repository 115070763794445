import { VuexModule, Module, Mutation, Action, getModule } from 'vuex-module-decorators'
import store from '@/store'

export interface MasterState {
  master: {
    isClientDataChanged: boolean,
    isCompanyDataChanged: boolean,
    isOperationDataChanged: boolean
  }

}

@Module({ dynamic: true, store, name: 'master' })
class Master extends VuexModule implements MasterState {
  public master = {
    isClientDataChanged: false,
    isCompanyDataChanged: false,
    isOperationDataChanged: false
  }

  @Mutation
  private setClientDataChange () {
    this.master.isClientDataChanged = true
  }

  @Mutation
  private resetClientDataChange () {
    this.master.isClientDataChanged = false
  }

  @Mutation
  private setCompanyDataChange () {
    this.master.isCompanyDataChanged = true
  }

  @Mutation
  private resetCompanyDataChange () {
    this.master.isCompanyDataChanged = false
  }

  @Mutation
  private setOperationDataChange () {
    this.master.isOperationDataChanged = true
  }

  @Mutation
  private resetOperationDataChange () {
    this.master.isOperationDataChanged = false
  }

  @Action
  public setClientChange () {
    this.setClientDataChange()
  }

  @Action
  public resetClientChange () {
    this.resetClientDataChange()
  }

  @Action
  public setCompanyChange () {
    this.setCompanyDataChange()
  }

  @Action
  public resetCompanyChange () {
    this.resetCompanyDataChange()
  }

  @Action
  public setOperationChange () {
    this.setOperationDataChange()
  }

  @Action
  public resetOperationChange () {
    this.resetOperationDataChange()
  }
}

export const MasterModule = getModule(Master)
