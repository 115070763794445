import APIService from '@/services/api-service'
const resource = '/client'

class ClientService extends APIService {
  public constructor () {
    super()
    this.mountAuthorizationToken()
  }

  public clients () {
    return this.connector.get(`${resource}/all`)
  }

  public getClientNames () {
    return this.connector.get(`${resource}/names`)
  }

  /* public getClient (id: any) {
    return this.connector.get(`${resource}/modal/${id}`)
  } */

  /* public checkClient (code: any) {
    return this.connector.get(`${resource}/check/${code}`)
  } */

  public save (data: any) {
    return this.connector.post(`${resource}/save`, data)
  }

  public saveContacts (data: any) {
    return this.connector.post(`${resource}/contacts/save`, data)
  }

  /* public savePayments (clientId, data: any) {
    return this.connector.put(`${resource}/payments/save`, { client_id: clientId, payment_details: data })
  } */

  public savePayments (data: any) {
    return this.connector.put(`${resource}/payments/save`, data)
  }

  public update (data: any) {
    return this.connector.put(`${resource}/update/`, data)
  }

  public delete (id: any) {
    return this.connector.delete(`${resource}/${id}`)
  }

  public getRunningNumber () {
    return this.connector.get(`${resource}/running/number`)
  }

  public getServiceRates (sourceId) {
    return this.connector.get(`${resource}/rates/${sourceId}`)
  }

  public getServices () {
    return this.connector.get('/services')
  }

  public getPaymentTerms () {
    return this.connector.get(`${resource}/payment-terms`)
  }

  public saveRates (clientId, data: any) {
    return this.connector.post(`${resource}/rates/save`, { client_id: clientId, service_rates: data })
  }

  public saveVessels (clientId, data: any) {
    return this.connector.post(`${resource}/vessels/save`, { client_id: clientId, vessels: data })
  }

  public deleteRate (clientId: any, serviceId: any) {
    return this.connector.delete(`${resource}/rates/${clientId}/${serviceId}`)
  }

  public deleteVessel (vesselId) {
    return this.connector.delete(`${resource}/vessel/${vesselId}`)
  }

  public saveBankingDetails (data: any) {
    return this.connector.post(`${resource}/bank-details/save`, data)
  }

  public deleteBankingDetails (bankId) {
    return this.connector.delete(`${resource}/bank/${bankId}`)
  }

  public getBankingDetails (clientId) {
    return this.connector.get(`${resource}/${clientId}/bank/all`)
  }
}

export default new ClientService()
